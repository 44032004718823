<template>
    <div class="container">
      <div class="title">{{title}}</div>
      <div v-html="content" class="content"></div>
    </div>
</template>
<script>
  import https from '../../https.js'
export default {
  data() {
    return {
      content: '',
	  title: "",
    }
  },
  created() {
    this.getContent()
  },
  methods: {
    getContent() {
      // this.axios.get("https://dev.kypro.ikaoyaner.com/api/cms/article/byCode/app-fwtk").then(res => {
      //   const { data } = res
      //   if(data.code == 200) {
      //     this.content = data.data.content
      //   }
      // })
      https.fetchGet('/api/cms/article/byCode/app-fwtk').then(res => {
        console.log(res)
        const {
          data
        } = res;
        if (data.code == 200) {
          this.content = data.data.content
		  this.title = data.data.title
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>
<style lang="less" scoped>
  
  .container{
    
    .title {
      padding: 20px 12px 10px 12px;
      font-size: 24px;
      color: #444;
      border-bottom: 1px solid #ebebeb;
    }
    .content{
      padding: 0 12px;
    }
  }
</style>